<template>
  <footer class="main-footer">
    <strong>Copyright &copy; {{ new Date().getFullYear() }}</strong>
    <span class="ml-1">GPING All rights reserved.</span>
    <div class="d-none d-sm-inline-block ml-auto">
      <b>Version</b> {{ version_number }}
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      version_number: '1.3.2',
    };
  },
};
</script>
