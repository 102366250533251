<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link hamburger_menu"
          data-widget="pushmenu"
          href="#"
          role="button"
          @click.stop.prevent="toggle_sidebar"
        >
          <span class="hamburger_line" />
          <span class="hamburger_line" />
          <span class="hamburger_line" />
        </a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <div class="navbar-nav ml-auto">
      <div class="logout d-flex align-items-center">
        <span class="d-flex align-items-center justify-content-center">{{ login.admin_name.substring(0, 1) }}</span>
        <a
          href="#"
          @click.prevent="logout()"
        >登出</a>
      </div>
    </div>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['main_sidebar_status']),
    ...mapGetters('admin', ['login']),
  },
  methods: {
    /* 登出 */
    logout() {
      const vm = this;
      vm.$swal({
        title: '請問是否要登出?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '登出',
      }).then((result) => {
        if (result.value) {
          vm.$swal({
            icon: 'success',
            title: '已登出!',
            confirmButtonText: '關閉視窗',
          });
          const loginData = {
            adminName: '',
            adminId: '',
            loginStatus: false,
            loginToken: '',
          };
          localStorage.removeItem('gping_admin');
          localStorage.removeItem('gping_adminId');
          vm.$store.dispatch('admin/save_loginstatus', loginData);
          vm.$router.push('/login');
        }
      });
    },
    /* toggle_sidebar */
    async toggle_sidebar() {
      const vm = this;
      vm.$store.dispatch('toggle_sidebar', !vm.main_sidebar_status);
    },
  },
};
</script>
