<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar elevation-4">
    <!-- Brand Logo -->
    <router-link
      to="/dashboard"
      class="brand-link"
    >
      <div class="brand_image">
        <img
          src="@/assets/images/logo.svg"
          alt=""
          class="img-fluid"
        >
      </div>
    </router-link>
    <!-- Sidebar -->
    <overlay-scrollbars
      class="sidebar"
      :options="{ scrollbars: { autoHide: 'scroll' } }"
    >
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li
            v-for="item in section_data"
            :key="item.name"
            class="nav-item"
          >
            <router-link
              :to="item.link"
              class="nav-link"
              :class="{ 'nav-link_active': item.id === now_page }"
            >
              <div class="nav-img">
                <img
                  :src="item.img_url"
                  alt=""
                  class="img-fluid"
                >
              </div>
              <span>{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </overlay-scrollbars>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      section_data: [
        {
          img_url: require('@/assets/images/index/briefcase.svg'),
          name: '消息',
          link: '/dashboard/news',
        },
        {
          img_url: require('@/assets/images/index/grid.svg'),
          name: '品類',
          id: 'category',
          link: '/dashboard/category',
        },
        {
          img_url: require('@/assets/images/index/setting.svg'),
          name: '商品',
          id: 'product',
          link: '/dashboard/product',
        },
        {
          img_url: require('@/assets/images/index/layout.svg'),
          name: '版位',
          id: 'indexboard',
          link: '/dashboard/indexboard',
        },
        {
          img_url: require('@/assets/images/index/volume.svg'),
          name: '活動',
          id: 'saleevent',
          link: '/dashboard/saleevent',
        },
        {
          img_url: require('@/assets/images/index/bar-chart.svg'),
          name: '業績',
          id: 'performance',
          link: '/dashboard/performance',
        },
        {
          img_url: require('@/assets/images/index/file.svg'),
          name: ' 訂單',
          id: 'orders',
          link: '/dashboard/orders',
        },
        {
          img_url: require('@/assets/images/index/user.svg'),
          name: '會員',
          id: 'member',
          link: '/dashboard/member',
        },
        {
          img_url: require('@/assets/images/index/package.svg'),
          name: '庫存',
          id: 'stock',
          link: '/dashboard/stock',
        },
        {
          img_url: require('@/assets/images/index/shop.svg'),
          name: '門市',
          id: 'store',
          link: '/dashboard/store',
        },
        {
          img_url: require('@/assets/images/index/code.svg'),
          name: '優惠碼',
          id: 'couponcode',
          link: '/dashboard/couponcode',
        },
        {
          img_url: require('@/assets/images/index/statistics.svg'),
          name: '統計',
          id: 'statistics',
          link: '/dashboard/statistics',
        },
        {
          img_url: require('@/assets/images/index/setting.svg'),
          name: '系統',
          id: 'system',
          link: '/dashboard/system',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['now_page']),
    ...mapGetters('admin', ['login']),
  },
  // mounted() {
  //   /* 啟用 tooltip */
  //   $('[data-toggle="tooltip"]').tooltip();
  // },
};
</script>
